<template>
  <ul
    :class="$style.root"
  >
    <li v-for="(localeOption, index) in localeOptions">
      <layout-animated-underline-component
        :component="NuxtLink"
        :to="switchLocalePath(localeOption.code)"
        :class="$style.link"
        :active="locale === localeOption.code"
        :ignore="locale === localeOption.code"
      >
        {{ formatCode(localeOption.code) }}
      </layout-animated-underline-component>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'

const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp
const { locales, defaultLocale, locale } = $i18n
const switchLocalePath = useSwitchLocalePath()

const props = defineProps({
  localeOptions: {
    type: Array,
    required: true
  }
})

const formatCode = (code: string) => {
  return code?.toUpperCase()?.split('-')?.[0]
}
</script>

<style module>
.root {
  composes: reset-list from global;
  display: flex;
  gap: var(--unit--horizontal);
  justify-content: flex-end;
}

.link:global(.router-link-exact-active) {
  color: var(--color--secondary);
}
</style>
