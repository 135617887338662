<template>
  <teleport
    v-if="localizations?.length && localeOptions && show && !onModalPage"
    to="#teleport-site-nav-lang-switch"
  >
    <navigation-locale-menu-options-component
      :localeOptions="localeOptions"
      :class="$style.withinSiteNav"
    />
  </teleport>
  <menu
    v-else-if="localizations?.length && localeOptions && show"
    :class="$style.root"
  >
    <navigation-locale-menu-options-component
      :localeOptions="localeOptions"
      :class="$style.onPage"
    />
  </menu>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'

const appVariant = getAppVariant()
const show = getAppVariant() === 'interim'

const mainStore = useMainStore()
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp
const { locales, defaultLocale, locale } = $i18n

const props = defineProps({
  localizations: {
    type: Array,
    required: false
  },
  onModalPage: {
    type: Boolean,
    required: false
  }
})

const localizationArray = props?.localizations || []

const presentLocales = [locale.value, ...localizationArray?.map((localization: any) => localization?.locale)]?.filter((locale: any) => locale)

const localeOptions = locales.value?.filter( (locale: any) => presentLocales?.includes(locale?.code) )
</script>

<style module>
.root {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.withinSiteNav {
  composes: font-size-large from global;
}

.onPage {
  composes: font-size-medium from global;

  margin-bottom: var(--font-default--line-height);
}

</style>
